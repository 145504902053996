import React from "react";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import vector from "../../../assets/Group.png";
import featuresimg1 from "../../../assets/Welcome - 3 - Static.png";
import featuresimg2 from "../../../assets/Image [attachment-full].png";
import featuresimg3 from "../../../assets/Image [attachment-full] (1).png";
import featuresimg4 from "../../../assets/Image [elementor-animation-shrink].png";
import featuresimg5 from "../../../assets/Image [attachment-full] (6).png";
import featuresimg6 from "../../../assets/Image [attachment-full] (7).png";
import cardimg1 from "../../../assets/Image [attachment-full] (4).png";
import cardimg2 from "../../../assets/Image [attachment-full] (5).png";

const ListBox = {
  width: { xs: "100%", sm: "100%", lg: "100%" },
  minHeight: { sm: "170px", md: "100px" },
  alignItems: "center",
  borderRadius: "10px",
  margin: {
    // sx:"0",
    // md: "0 2% 42px",
  },
  padding: "18px 15px 14px",
};

const ListItemTextStyling = {
  fontSize: "17px",
  fontFamily: "sans-serif",
  color: "#404040",
  // paddingLeft: "10px",
  paddingLeft: "15px",
  lineHeight: { xs: "1.5" },
  // border:"1px solid red"
};

const Section3 = () => {
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            height: {
              md: "255%",
              xs: "240%",
            },
            width: "100%",
            userSelect: "none",
          }}
        >
          <img
            src={vector}
            style={{
              position: "absolute",
              zIndex: -1,
              backgroundColor: "#ebfff0",
              width: "100%",
              height: "100%",
            }}
          />
        </Box>

        <Container
          maxWidth={"xl"}
          sx={{
            padding: {
              xl: "50px 80px",
              md: "20px 80px",
              sm: "40px 80px",
            },
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                padding: { xs: "20px", md: "20px" },
                display: "flex",
                alignItems: { xs: "center", xl: "flex-start" },
                justifyContent: { xs: "center", xl: "flex-start" },
              }}
            >
              <Box sx={{ width: "100%", ml: 0 }}>
                <img
                  alt=""
                  src={cardimg1}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                    transition: "transform 0.3s",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.transform = "scale(1.1)";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.transform = "scale(1)";
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                padding: { xs: "0px", md: "40px" },
                display: "flex",
                alignItems: { xs: "center" },
                justifyContent: { xs: "center" },
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  pt: {
                    xs: 0,
                  },
                }}
              >
                <List>
                  <ListItem sx={{ ...ListBox }}>
                    <img
                      src={featuresimg1}
                      alt="easy online application modes"
                    />
                    <Typography
                      display="inline"
                      sx={{ ...ListItemTextStyling }}
                    >
                      Our solution significantly reduces energy expenses,
                      allowing society to allocate resources towards after
                      community initiatives.
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ ...ListBox }}>
                    <img
                      src={featuresimg2}
                      alt="easy online application modes"
                    />
                    <Typography
                      display="inline"
                      sx={{ ...ListItemTextStyling }}
                    >
                      Adopting our solutions contributes to a cleaner, greener
                      planet, preserving nature's natural resources for future
                      generations.
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ ...ListBox }}>
                    <img
                      src={featuresimg3}
                      alt="easy online application modes"
                    />
                    <Typography
                      display="inline"
                      sx={{ ...ListItemTextStyling }}
                    >
                      Foster active participation in sustainability initiatives,
                      enhancing community cohesion and environmental awareness.
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box>
        <Container
          maxWidth={"xl"}
          sx={{
            padding: {
              xl: "50px 80px",
              md: "20px 80px",
              sm: "40px 80px",
            },
            flexDirection: {
              xs: "reverse",
            },
            mt: {
              xl: "0px",
              md: "0px",
            },
          }}
        
          
        >
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: { xs: "center" },
              justifyContent: { xs: "center" },
              flexDirection: {
                md: "row",
                xs: "column-reverse",
              },
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                padding: { xs: "0px", sm: "48px", md: "48px" },
                display: "flex",
                alignItems: { xs: "center" },
                justifyContent: { xs: "center" },
                flexDirection: {
                  md: "column",
                  xs: "reverse",
                },
              }}
            >
              <Box>
                <List>
                  <ListItem sx={{ ...ListBox }}>
                    <img
                      src={featuresimg4}
                      alt="easy online application modes"
                    />
                    <Typography
                      display="inline"
                      sx={{ ...ListItemTextStyling }}
                    >
                      We offer customized financing solutions to housing
                      societies to facilitate project implementation without
                      upfront costs and with manageable EMIs.
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ ...ListBox }}>
                    <img
                      src={featuresimg5}
                      alt="easy online application modes"
                    />
                    <Typography
                      display="inline"
                      sx={{ ...ListItemTextStyling }}
                    >
                      Each housing society receives tailored solutions based on
                      their unique energy needs and sustainability goals.
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ ...ListBox }}>
                    <img
                      src={featuresimg6}
                      alt="easy online application modes"
                    />
                    <Typography
                      display="inline"
                      sx={{ ...ListItemTextStyling }}
                    >
                      Clients gain access to the AlterHome ecosystem, enhancing
                      their capabilities with extensive resources, expertise,
                      and opportunities
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                padding: { xs: "20px", sm: "0" },
                display: "flex",
                alignItems: { xs: "center", xl: "flex-start" },
                justifyContent: { xs: "center", xl: "flex-start" },
              }}
            >
              <Box>
                <img
                  alt="loading"
                  src={cardimg2}
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    transition: "transform 0.3s",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.transform = "scale(1.1)";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.transform = "scale(1)";
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Section3;

import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Link } from "@mui/material";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import Button from "@mui/material/Button";
import vector from "../../assets/Group.png";
import contact from "../../assets/contact.png";
// import { Email } from "@mui/icons-material";
const redirectToForm = () => {
  window.location.href = "https://forms.gle/zYY9kWjyCjJb7Lr3A";
};

const Contact = () => {
  const openForm = () => {
    window.open("https://www.linkedin.com/company/altercarbon/", "_blank"); // Opens in a new tab
  };
  const handlePhoneNumberClick = () => {
    // Perform actions when phone number is clicked, such as redirecting to a call app
    // For example, you can use window.location.href to redirect to a specific URL
    window.location.href = 'tel:9102048555520';
  };
  return (
    <div>
      <Box
        sx={{
          marginTop: {
            // md: "100px",
            // xs: "70px",
          },
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
            marginTop: {
              // md: "100px",
              xs: "40px",
            },
          }}
        >
          <Grid
            container
            sx={{
              position: "relative",
              width: "100%",
              height: "100vh",
              maxHeight: {
                xs: "calc(100vh - 130px)",
                md: "calc(100vh - 41px)",
              },
              overflow: "hidden",
            }}
          >
            <Grid
              container
              sx={{
                position: "relative",
                width: "100%",
                height: "100vh",
                overflow: "hidden",
              }}
            >
              <img
                src={vector}
                alt="Background vector"
                style={{
                  position: "absolute",
                  top: "0",
                  zIndex: -1,
                  backgroundColor: "#ebfff0",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: {
                    md: "flex-start",
                    xs: "flex-start",
                  },
                  gap: {
                    md: 2,
                    xs: 0,
                  },
                  mb: 3,
                  pl: {
                    md: "120px",
                    xs: "30px",
                  },
                  position: "relative",
                  zIndex: 2,
                  mt: {
                    md: "75px", // Added top margin for md screen size
                    xs: "20px",
                  },
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    color: "#202220",
                    fontWeight: "bold",
                    fontSize: {
                      md: "4rem",
                      xs: "2.5rem",
                    },
                    mt: {
                      lg: 2,
                      md: 3,
                      xs: 4,
                    },
                  }}
                >
                  Reach out to us
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#3CB769",
                    "&:hover": {
                      backgroundColor: "#34A044", // Change to the desired color on hover
                    },
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    padding: {
                      md: "4px 8px", // Reduced padding for medium screens
                      xs: "6px 12px", // Adjust padding for small screens
                    },
                    fontSize: {
                      md: "1.2rem", // Reduced font size for medium screens
                      xs: "0.875rem", // Adjust font size for small screens
                    },
                    borderRadius: "1.8vw", // Add rounded corners
                    minWidth: {
                      md: "180px", // Reduced minimum width for medium screens
                      xs: "80px", // Reduced minimum width for small screens
                    },
                    height: {
                      md: "65px", // Fixed height for medium screens
                      xs: "45px", // Fixed height for small screens
                    },
                    whiteSpace: "nowrap", // Prevent text wrapping
                    textTransform: "capitalize", // Capitalize button text
                    mt: 1, // Margin top for spacing
                  }}
                  onClick={redirectToForm} // Redirects to the specified URL when clicked
                >
                  Contact Us <ArrowForwardIcon sx={{ ml: 1 }} />
                </Button>
                <Box
      sx={{
        mt: "20px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <PhoneIcon sx={{ marginRight: 1 }} />{" "}
        {/* Add margin-right for spacing */}
        <Typography
          sx={{
            color: "#202220",
            fontWeight: "bold",
            fontSize: "1.5rem",
          }}
        >
          Give us a call
        </Typography>
      </Box>
      <Typography>
        <Link
          href="#"
          onClick={handlePhoneNumberClick}
          sx={{ textDecoration: "none", color: "#303330" }}
        >
          91-020-4855 5520
        </Link>
      </Typography>
    </Box>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <EmailOutlinedIcon sx={{ marginRight: 1 }} />{" "}
                    {/* Add margin-right for spacing */}
                    <Typography
                      sx={{
                        color: "#202220",
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                      }}
                    >
                      Send us an email
                    </Typography>
                  </Box>
                  <Typography>
                    <Link
                      href="mailto:hello@altercarbon.in"
                      sx={{ textDecoration: "none", color: "#303330" }}
                    >
                      hello@altercarbon.in
                    </Link>
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  position: "relative",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    component="img"
                    src={contact}
                    alt="Contact us"
                    sx={{
                      width: "100%",
                      objectFit: "cover",
                      height: {
                        xs: "35%", // 40% height for small devices
                        md: "100%", // 100% height for medium and larger devices
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                position: "relative",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: {
                    md: "flex-start",
                    xs: "flex-start",
                  },
                  gap: {
                    md: 2,
                    xs: 2,
                  },
                  mb: 6,
                  pl: {
                    md: "120px",
                    xs: "30px",
                  },
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    color: "#3CB769",
                    fontWeight: "bold",
                    fontSize: {
                      md: "2rem",
                      xs: "6rem",
                    },
                    mt: {
                      md: "30px",
                      lg: "0",
                      xs: "10vh",
                    },
                  }}
                >
                  Reach out to us
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  gap: 2,
                  mb: 6,
                  p: {
                    md: "100px",
                    xs: "28px",
                  },
                }}
              >
                {/* Additional content here */}
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                mt: { md: "4rem" },
                mb: { md: "3rem" },
                width: "100%",
                p: { xs: 2, sm: 0 },
              }}
            >
              {/* Additional content here */}
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                position: "relative",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <img
                  src={contact}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  alt="Background"
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              mt: { md: "4rem" },
              mb: { md: "3rem" },
              width: "100%",
              p: { xs: 2, sm: 0 },
            }}
          >
            {/* Additional content here */}
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                // mt: 3,
                mb: 2,
                pl: {
                  md: "90px",
                  xs: "5px",
                },
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  pl:{
                    // md: "95px",
                    xs: "16px",
                  },
                  fontSize: { xs: "1.2rem", sm: "1.5rem" },
                  fontWeight: "bold", // Adding bold weight
                  width: "97%",
                }}
              >
                <span style={{ color: "#3CB769" }}>JOIN OUR TEAM: </span>
                EXPLORE EXCITING OPPORTUNITIES WITH US!
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            backgroundColor: "#DEEFE5",
            width: "90%",
            margin: "0 auto",
            p: { xs: 2, sm: 4 },
            borderRadius: "10px",
            mb: 5,
          }}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12} sx={{ maxWidth: "100%" }}>
              <Box sx={{ px: { xs: 2, sm: 0 } }}>
                <Typography
                  variant="body"
                  sx={{
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    fontWeight: "light",
                  }}
                >
                  Altercarbon is more than just a platform, it's a catalyst for
                  transformation.
                  <br />
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "0.9rem", sm: "1rem" },
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    We believe in the power of collaboration, innovation, and
                    strategic guidance to navigate the complexities of
                    sustainability transitions. With a dedicated team of experts
                    and a steadfast commitment to our mission, we're here to
                    drive meaningful impact for our partners and the planet.
                  </Typography>
                  <Box
                    sx={{
                      display: {
                        md: "initial",
                        xs: "flex",
                      },
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "20px",
                        flex: { xs: 4, sm: 8, md: 12 },
                        minWidth: "12%",
                        marginRight: "5px",
                        textTransform: "capitalize",
                        whiteSpace: "nowrap",
                        backgroundColor: "#3CB769",
                        "&:hover": {
                          backgroundColor: "#3CB769", // Change to the desired color on hover
                        },
                      }}
                      onClick={openForm}
                    >
                      View Openings
                    </Button>

                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: "20px",
                        flex: { xs: 4, sm: 8, md: 12 },
                        minWidth: "12%",
                        marginLeft: "5px",
                        textTransform: "capitalize",
                        whiteSpace: "nowrap",
                        borderColor: "#3CB769",
                        "&:hover": {
                          borderColor: "#3CB769",
                        },
                        color: "black",
                      }}
                      onClick={() =>
                        (window.location.href = "mailto:hello@altercarbon.in")
                      }
                    >
                      Send Email
                    </Button>
                  </Box>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};
export default Contact;

import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AboutImg from "../../assets/image 79 (1).png";
import PlantImg from "../../assets/image 80.png";
import ContactButton from "../floatingcontactus/contactbutton";

const About = () => {
  const [changingWords, setChangingWords] = useState([
    "sustainable",
    "eco-friendly",
    "inclusive",
    "equitable",
  ]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex((prevIndex) =>
        prevIndex === changingWords.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => clearInterval(intervalId);
  }, [changingWords]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Box style={{ position: "relative" }}>
            <Box style={{ width: "100%", height: "64vh", overflow: "hidden" }}>
              <img
                src={AboutImg}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
            <Box
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    md: "5rem",
                    xs: "2rem",
                  },
                  fontWeight: 900,
                }}
              >
                altercarbon
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          mt: 6,
          mb: 6,
          pl: {
            xl: "95px",
            md: "70px",
            xs: "20px",
          },
        }}
      >
        <Box>
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.5rem" },
              fontWeight: "bold",
              width: "100%",
              color: "#3CB769",
            }}
          >
            ENABLING NET-ZERO TRANSITIONS
          </Typography>
        </Box>
      </Grid>
      <Box
        sx={{
          backgroundColor: "#DEEFE5",
          width: "90%",
          margin: "0 auto",
          p: { xs: 2, sm: 4 },
          borderRadius: "10px",
          mb: 5,
        }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} sx={{ maxWidth: "100%" }}>
            <Box sx={{ px: { xs: 2, sm: 0 } }}>
              <Typography
                variant="body"
                sx={{
                  fontSize: { xs: "1.2rem", sm: "1.5rem" },
                  fontWeight: "bold",
                }}
              >
                Altercarbon is more than just a platform, it's a catalyst for
                transformation.
                <br />
                <Typography
                  variant="body1"
                  sx={{ fontSize: { xs: "0.9rem", sm: "1rem" }, mt: 2 }}
                >
                  At Altercarbon, we're committed to driving sustainable change
                  by empowering businesses to embrace net zero transitions. Our
                  comprehensive platform offers a suite of products and services
                  designed to support companies at every stage of their
                  sustainability journey. We believe in the power of
                  collaboration, innovation, and strategic guidance to navigate
                  the complexities of sustainability transitions. With a
                  dedicated team of experts and a steadfast commitment to our
                  mission, we're here to drive meaningful impact for our
                  partners and the planet.
                </Typography>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        sx={{ width: "100%", p: 4, height: "100%", backgroundColor: "#DEEFE5" }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: 2,
            mt: 6,
            mb: 6,
            pl: {
              md: "100px",
              xs: "0px",
            },
          }}
        >
          <Box
            sx={{
              width: {
                md: "80%",
              },
              paddingTop: "70%",
              position: "relative",
            }}
          >
            <img
              src={PlantImg}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "20px",
                transition: "transform 0.3s",
              }}
              onMouseOver={(e) => {
                e.target.style.transform = "scale(1.1)";
              }}
              onMouseOut={(e) => {
                e.target.style.transform = "scale(1)";
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            mt: { md: 8, xs: 3 },
            mb: 6,
            width: "100%",
            paddingTop: "3%",
            pr: {
              md: "100px",
              xs: "0px",
            },
          }}
        >
          <Box>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "#3CB769" }}
            >
              WHO WE ARE
            </Typography>
            <Typography sx={{ mt: 4, fontWeight: "bold" }} variant="h5">
              Our Mission
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" }, mt: 2 }}
            >
              To lead the charge in sustainable transformation, Altercarbon is
              dedicated to empowering businesses to embrace net-zero
              transitions. Through our comprehensive platform and expert
              guidance, we drive impactful change and inspire a global movement
              towards a more sustainable future.
            </Typography>
            <Typography sx={{ mt: 4, fontWeight: "bold" }} variant="h5">
              Our Vision
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" }, mt: 2 }}
            >
              At Altercarbon, we envision a world where businesses operate in
              harmony with the planet, driving economic prosperity while
              preserving natural resources for future generations. Through
              collaboration, innovation, and unwavering commitment, we strive to
              make this vision a reality.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            width: {
              xs: "70vw",
              lg: "50vw",
              md: "60vw",
            },
            mt: "10vh",
            mb: "10vh",
            fontWeight: "bold",
            fontSize: {
              md: "3rem",
              xs: "1.7rem",
            },
            pl: {
              md: "100px",
              xs: "20px",
            },
            color: "black", // Set color to bright red
          }}
        >
          MAKING THE WORLD MORE {" "}
          <span
            style={{
              fontWeight: "bold",
              fontFamily: "cursive",
              color: "#34BC56", // Set color of changing word to red
            }}
          >
            {changingWords[currentWordIndex]}
          </span>
        </Typography>
      </Box>
      <ContactButton redirectUrl="https://forms.gle/zYY9kWjyCjJb7Lr3A" />
    </Box>
  );
};
export default About;

import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Arrow from "../../../assets/arrow.png";

const Seection2 = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#303330",
        m: 0,
        minHeight: "250px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <Box sx={{ padding: { xs: "30px 10px", md: "32px" } }}>
          <Typography
            textAlign="center"
            sx={{
              fontSize: { xs: "35px", sm: "40px", lg: "45px" },
              fontFamily: "sans-serif",
              fontWeight: "100",
              color: "white",
              lineHeight: "1.5",
              mt: 2,
            }}
          >
            Enabling
            <Typography
              component={"span"}
              sx={{
                color: "white",
                fontSize: { xs: "36px", sm: "40px", lg: "45px" },
                fontFamily: "sans-serif",
                fontWeight: "900",
                lineHeight: "1.5",
                padding: "0px 0px 20px",
              }}
            >
              &nbsp;Net Zero
            </Typography>{" "}
            Transitions
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 1,
                mb: 2,
                width: {
                  xs: "90%",
                  lg: "40%",
                },
              }}
            >
              <img src={Arrow} style={{ width: "100%" }} />
            </Box>
          </Box>

          <Typography
            textAlign="center"
            sx={{
              fontFamily: "sans-serif",
              fontSize: { xs: "17px", sm: "18px", lg: "21px" },
              margin: "0px 0px 32px",
              color: "#FFFFFF",
              fontWeight: 500,
           
            }}
          >
            At AlterCarbon, we're committed to driving sustainable change by
            empowering businesses to embrace net zero transitions. Our
            comprehensive platform offers a suite of product service designs to
            support companies at every stage of their sustainability journey.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Seection2;

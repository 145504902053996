import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

const Section4 = () => {
  const [button1Text, setButton1Text] = useState("Sustainability");
  const [button2Text, setButton2Text] = useState("Decarbonization");
  const [button3Text, setButton3Text] = useState("Innovation");
  const [activeButton, setActiveButton] = useState(2);

  const handleButtonClick = (buttonNumber, buttonText) => {
    if (activeButton !== buttonNumber) {
      setActiveButton(buttonNumber);
    }
    switch (buttonNumber) {
      case 1:
        setButton1Text(buttonText);
        break;
      case 2:
        setButton2Text(buttonText);
        break;
      case 3:
        setButton3Text(buttonText);
        break;
      default:
        break;
    }
  };

  const getButtonColor = (buttonNumber) => {
    return activeButton === buttonNumber ? "white" : "black";
  };
  const getButtonBg = (buttonNumber) => {
    return activeButton === buttonNumber ? "white" : "transparent";
  };
  const buttonTexts = {
    1: "Navigate towards a cleaner, more sustainable energy future with our Energy Transition services. Whether implementing renewable energy solutions or optimizing energy efficiency, we assist organizations in minimizing their environmental footprint while maximizing operational performance.",
    2: "Lead the charge towards a carbon-neutral future with our Decarbonization services. We work closely with organizations to develop strategies for reducing carbon emissions across all operational phases, ensuring a sustainable and responsible approach.",
    3: "At the intersection of innovation and sustainability, our advisory services offer a dynamic framework for organizations to pioneer groundbreaking solutions and unlock untapped opportunities. Through strategic capitalization, energy transition initiatives, and sustainable manufacturing breakthroughs, we catalyze innovation-driven growth that redefines industry standards",
  };

  return (
    <Box
      sx={{
        width: "100vw",
        p: {
          md: "100px",
          xs: "30px",
        },
        background:
          "linear-gradient(90deg, rgba(52,188,86,1) 28%, rgba(32,149,215,1) 85%)",
          overflow: "hidden",
      }}
    >
      <Container>
        <Grid container>
          <Grid
            lg={4}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              sx={{
                textTransform: "capitalize",
                border: ` 1px solid ${getButtonBg(1)} `,
                color: getButtonColor(1),
                padding: {
                  md: "10px 40px",
                  xs: "10px 40px",
                },
                fontSize: {
                  md: "2rem",
                  xs: "1.2rem",
                },
                borderRadius: "38px",
                textTransform: "capitalize",
                // "&:hover": {
                //   border: "1px solid blacK",
                //   color: "white",
                // },
              }}
              onClick={() => handleButtonClick(1, "Sustainability")}
            >
              {button1Text}
            </Button>
          </Grid>
          <Grid
            lg={4}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              sx={{
                border: ` 1px solid ${getButtonBg(2)} `,

                color: getButtonColor(2),
                padding: {
                  md: "10px 40px",
                  xs: "10px 40px",
                },
                fontSize: {
                  md: "2rem",
                  xs: "1.2rem",
                },
                borderRadius: "38px",
                textTransform: "capitalize",
                // "&:hover": {
                //   border: "1px solid blacK",
                //   color: "white",
                // },
              }}
              onClick={() => handleButtonClick(2, "Decarbonization")}
            >
              {button2Text}
            </Button>
          </Grid>
          <Grid
            lg={4}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              sx={{
                border: ` 1px solid ${getButtonBg(3)} `,

                color: getButtonColor(3),
                padding: {
                  md: "8px 40px",
                  xs: "10px 40px",
                },
                fontSize: {
                  md: "2rem",
                  xs: "1.2rem",
                },
                borderRadius: "38px",
                textTransform: "capitalize",
                // "&:hover": {
                //   border: "1px solid blacK",
                //   color: "white",
                // },
              }}
              onClick={() => handleButtonClick(3, "Innovation")}
            >
              {button3Text}
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Grid
          sx={{
            mt: "30px",
            width: {
              md: "70vw",
              xs: "90vw",
            },
          }}
        >
          <div>
            {activeButton && (
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "sans-serif",
                  fontSize: {
                    md: "1.3rem",
                    xs: "1.2rem",
                  },
                  textAlign: "center",
                }}
              >
                {buttonTexts[activeButton]}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Section4;

import React from 'react';
// import Button from '@mui/material/Button';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Typography } from '@mui/material';

const ContactButton = ({ redirectUrl }) => {
  const handleClick = () => {
    window.open(redirectUrl, '_blank');
  };

  return (
    <Box
  onClick={handleClick}
  sx={{
    position: 'fixed',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    width: {
      xs: '30px', // Smaller size for extra small screens
      // sm: '40px', // Adjusted size for small screens
      md: '50px', // Default size for medium and above screens
    },
    height: {
      xs: '70px', // Smaller size for extra small screens
       // Adjusted size for small screens
      md: '120px', // Default size for medium and above screens
    },
    bgcolor: 'grey.900',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 1000,
    borderRadius: '20px 0 0 20px', // Rounded outline on the left side
    '&:hover': {
      bgcolor: 'grey.800',
    },
  }}
>
  <Typography
    sx={{
      transform: 'rotate(90deg)', // Rotate the text 90 degrees
      fontSize: {
        xs: '10px', // Smaller font size for extra small screens
        
        md: '18px', // Default font size for medium and above screens
      
      },
      color: '#A9A9A9', // Elegant grey color
      textAlign: 'center', // Center the text vertically
    }}
  >
    <strong> Contact Us</strong>
  </Typography>
</Box>




  );
};

export default ContactButton;
